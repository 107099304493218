import React from 'react';
import GlobalStyle from '../styles/Global';

const Global = ({ children }) => (
  <>
    <GlobalStyle />
    {children}
  </>
);

export default Global;
