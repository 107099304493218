export const colors = {
  black: '#000000',
  white: '#ffffff',
  gray: '#e2e2e2',
  lineGray: '#efefef',
  lightGray: '#f8f8f8',
  darkGray: '#9d9fa2',
  darkerGray: '#636466',
  green: '#96AD41',
  orange: '#EF8102',
  blue: '#00d5ff',
  middleBlue: '#c5f3ff',
  lightBlue: '#E8F9FD',
  yellow: '#ffd500',
  middleYellow: '#FFE25A',
  lightYellow: '#FFF9DE',
  red: '#b80000',
  blackOverlay: 'rgba(0, 0, 0, .6)',
  blackOverlay2: 'rgba(0, 0, 0, .3)',
  whiteGradient:
    'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
  blueGradient:
    'linear-gradient(90deg, rgba(203,244,255,1) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,0) 75%)',
  blueGradientMobile:
    'linear-gradient(90deg, rgba(203,244,255,1) 0%, rgba(255,255,255,0.8) 80%, rgba(255,255,255,0.4) 100%)',
  blueGradientBanner:
    'linear-gradient(0deg, rgba(255,255,255,0) 0%,  rgba(197, 243, 255, 1) 100%)',
};

export const font = {
  primary: 'league-spartan, sans-serif',
  secondary: 'century-gothic, sans-serif',
};

export const spacing = {
  headerHeight: '88.5px',
  headerMobile: '76.38px',
  headerAlert: '125px',
};

export const breakpoints = {
  mobile: 480,
  ipadLand: 1024,
  headerBreak: 1140,
  ipadMid: 960,
  midTab: 896,
  ipadPort: 768,
  pageWidth: 906,
  doubleWide: 1812,
};

export const misc = {
  sectionMargin: 100,
  sectionMobileMargin: 50,
  animSpeed: '.3s',
};
